import React, { useState } from "react";
import { Grid, MenuItem, Select, FormControl, InputLabel, TextField, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import swal from "sweetalert";
import Auth from "Auth"; // Assuming you have an Auth file

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  "& > *": {
    marginBottom: "1rem",
  },
});

const AddForm = () => {
  const { token } = Auth();
  let config = {
    headers: {
      Authorization: token,
    },
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    letterNo: "",
    applicablePeriod: "",
    daRate: "",
    type: "",
    date: "",
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = (prop) => (event) => {
    let value = event.target.value;

    if (event.target.type === "date") {
      const selectedDate = new Date(event.target.value);
      const formattedDate = `${selectedDate.getDate().toString().padStart(2, "0")}-${(selectedDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${selectedDate.getFullYear()}`;
      value = formattedDate;
    }

    setFormData({
      ...formData,
      [prop]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const body = {
        letterNo: formData.letterNo,
        applicablePeriod: formData.applicablePeriod,
        daRate: formData.daRate,
        type: formData.type,
        date: formData.date,
        remarks: formData.remarks,
      };

      const response = await axios.post("https://vkfpe87plb.execute-api.ap-south-1.amazonaws.com/production/godda_prc", body, config);
      swal({
        title: "Success!",
        text: "Data Added!!",
        icon: "success",
        button: "Ok!",
      });
    } catch (e) {
      swal({
        title: "Success!",
        text: "Data Added!! ",
        icon: "success",
        button: "Ok!",
      });
    } finally {
      setLoading(false);
      setFormData({
        letterNo: "",
        applicablePeriod: "",
        daRate: "",
        type: "",
        date: "",
        remarks: "",
      });
    }
  };

  if (loading) {
    return (
      <DashboardLayout>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20vh" }}>
          <CircularProgress />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <br />
      <br />
      <center>
        <h1>Add PRC</h1>
      </center>
      <div style={{ display: "block", margin: "20vh auto" }}>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="letterNo"
                label="Letter No"
                variant="outlined"
                name="letterNo"
                value={formData.letterNo}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="applicablePeriod"
                label="Applicable Period"
                variant="outlined"
                name="applicablePeriod"
                value={formData.applicablePeriod}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required id="daRate" label="DA Rate" variant="outlined" name="daRate" value={formData.daRate} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  label="Type"
                  style={{ height: "45px" }}
                  required
                >
                  <MenuItem value="1st prc">1st PRC</MenuItem>
                  <MenuItem value="2nd prc">2nd PRC</MenuItem>
                  <MenuItem value="3rd prc">3rd PRC</MenuItem>
                  <MenuItem value="4th prc">4th PRC</MenuItem>
                  <MenuItem value="5th prc">5th PRC</MenuItem>
                  <MenuItem value="6th prc">6th PRC</MenuItem>
                  <MenuItem value="7th prc">7th PRC</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <TextField
                label="Date"
                type="date"
                value={formData.date}
                onChange={handleInputChange("date")}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { style: { textTransform: "uppercase" } }, // Display input in uppercase
                }}
                required
              /> */}
              <TextField
                label="Date"
                type="date"
                value={formData.formattedDate}
                onChange={handleInputChange("date")}
                InputLabelProps={{ shrink: true }}
                InputProps={{ inputProps: { style: { textTransform: "uppercase" } } }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required id="remarks" label="Remarks" variant="outlined" name="remarks" value={formData.remarks} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="success" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      </div>
    </DashboardLayout>
  );
};

export default AddForm;
